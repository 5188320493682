import { useContext } from "react";
import { SearchParamContext, SearchParamContextState } from "context/SearchParamsContext";

const useSearchQueryParams = () => {
  const contextState = useContext<SearchParamContextState | null>(SearchParamContext);

  if (!contextState || typeof contextState === "undefined" || typeof contextState !== "object") {
    return {
      searchParams: undefined,
      setParams: () => {},
      clearParams: () => {},
      pushRoute: () => {},
      activeSearchContexts: undefined,
      pushActiveSearchContext: () => {},
      removeActiveSearchContext: () => {},
      resetActiveSearchContexts: () => {},
      updateHiddenSearchContextIds: () => {},
      hiddenSearchContextIds: undefined,
      currentSearchContextRecordId: undefined,
      updateCurrentSearchContextRecordId: () => {},
      currentSearchContextProjectId: undefined,
      updateCurrentSearchContextProjectId: () => {}
    };
  }

  return contextState as SearchParamContextState;
};

export default useSearchQueryParams;
