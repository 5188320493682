import { useContext } from "react";
import { MapState, TableActionsContext, TableActionsContextState } from "context/TableActionsContext";
import {
  BeforeAfterFileViewState,
  InlineCreationState,
  NoteState,
  SidebarState,
  SingleFileViewState
} from "types/baTypes";

const useTableActionsState = () => {
  const tableActionsState = useContext<TableActionsContextState | null>(TableActionsContext);

  if ((!tableActionsState && typeof tableActionsState === "undefined") || typeof tableActionsState !== "object") {
    return {
      tableRowsStateBySlug: undefined,
      tableSearchStateBySlug: undefined,
      cardApiRef: { current: null },
      gridApiRef: { current: null },
      boardApiRef: { current: null },
      mapApiRef: { current: null },
      setCardApiRef: () => {},
      setGridApiRef: () => {},
      setBoardApiRef: () => {},
      setMapApiRef: () => {},
      setTotalCount: () => {},
      setDataRowsIds: () => {},
      setDataFirstRowsIds: () => {},
      setTableSearchResults: () => {},
      sortingByTableSlug: undefined,
      filtersByTableSlug: undefined,
      updateSortingByTableSlug: () => {},
      updateFiltersByTableSlug: () => {},
      inlineCreationState: { isOpen: false } as InlineCreationState,
      updateInlineCreationState: () => {},
      singleFileViewState: { isOpen: false } as SingleFileViewState,
      updateSingleFileViewState: () => {},
      beforeAfterFileViewState: { isOpen: false } as BeforeAfterFileViewState,
      updateBeforeAfterFileViewState: () => {},
      sidebarState: { isOpen: false, visibleSidebars: [] } as SidebarState,
      updateSidebarState: () => {},
      muiFilterModel: undefined,
      setMuiFilterModel: () => {},
      rowDensityByTableSlug: undefined,
      updateRowDensityByTableSlug: () => {},
      columnVisibilityModelByTableSlug: undefined,
      updateColumnVisibilityModelByTableSlug: () => {},
      updateColumnsVisibilityModelByTableSlug: () => {},
      currentTablePage: undefined,
      updateCurrentTablePage: () => {},
      paginationByTableSlug: undefined,
      updatePaginationByTableSlug: () => {},
      gridPaginationByTableSlug: undefined,
      updateGridPaginationByTableSlug: () => {},
      bulkActionsStateByTableSlug: undefined,
      updateBulkActionsStateByTableSlug: () => {},
      noteState: {} as NoteState,
      updateNoteState: () => {},
      currentProjectId: undefined,
      updateCurrentProjectId: () => {},
      currentRecordId: undefined,
      updateCurrentRecordId: () => {},
      currentRecordTableName: undefined,
      updateCurrentRecordTableName: () => {},
      mapState: { isOpen: false } as MapState,
      updateMapState: () => {},
      detailViewRecordTriggeredByPath: undefined,
      updateDetailViewRecordTriggeredByPath: () => {},
      detailTabViewFilterByTableSlug: undefined,
      updateDetailTabViewFilterByTableSlug: () => {},
      groupByInfoByTableSlug: undefined,
      updateGroupByInfoByTableSlug: () => {},
      cardSizeByTableSlug: undefined,
      updateCardSizeByTableSlug: () => {},
      openFiltersByTableSlug: undefined,
      updateOpenFiltersByTableSlug: () => {},
      matrixXAxisColumnIdByTableSlug: undefined,
      updateMatrixXAxisColumnIdByTableSlug: () => {},
      triggerAction: undefined,
      updateTriggerAction: () => {},
      sendToActionByTableSlug: undefined,
      updateSendToActionByTableSlug: () => {},
      getTotalCountByTableSlug: () => {},
      pageConfigErrors: undefined,
      updatePageConfigErrors: () => {},
      formWebhookAddedRecordId: undefined,
      updateFormWebhookAddedRecordId: () => {},
      sectionFiltersByTableSlug: undefined,
      updateSectionFiltersByTableSlug: () => {},
      recordViewParentPage: undefined,
      updateRecordViewParentPage: () => {},
      recordHistoryState: [],
      deleteFromRecordHistoryState: () => {},
      updateRecordHistoryState: () => {},
      superAdminOrgIdFilterDisabledByTableSlug: undefined,
      updateSuperAdminOrgIdFilterDisabledByTableSlug: () => {}
    };
  }

  return tableActionsState as TableActionsContextState;
};

export type TableActionsContextType = ReturnType<typeof useTableActionsState>;

export default useTableActionsState;
