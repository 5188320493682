"use client";

import { Session, User } from "@supabase/supabase-js";
import React, { createContext, useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/navigation";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import useErrorLogger from "hooks/useErrorLogger";
import { getCurrentUser } from "lib/adminApi";
import { User as BAUser } from "types/apiTypes";
import { ERROR_TYPES, STATIC_SEARCH_PARAMS, USER_TYPE } from "utils/constants";
export interface SessionContextState {
  session?: Session | null;
  user?: User | null;
  isSessionLoading: boolean;
  currentUser?: BAUser;
  updateNewUserPhone: () => Promise<void>;
  updateCurrentUserOrgId: (orgId: string, redirectUrl?: string) => void;
  isUserInValidOrg: boolean;
  peopleDataFetched?: boolean;
}

export const SessionContext = createContext<SessionContextState | null>(null);

const { Provider } = SessionContext;

const SupabaseSessionContextProvider = ({
  children,
  initialSession,
  userOrgIdOverride
}: {
  children: React.ReactNode;
  initialSession: Session | null;
  userOrgIdOverride?: string;
}) => {
  const supabaseClient = useSupabaseBrowser();
  const { logError } = useErrorLogger();
  const router = useRouter();
  const [isSessionLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState<Session | null>();
  const [user, setUser] = useState<User | null>();
  const updatedClientRef = useRef(false);
  const [currentUserOrgId, setCurrentUserOrgId] = useState<string | undefined>(userOrgIdOverride); // This is updated when super admin changes organization

  const isClient = typeof window !== "undefined" && window.location.host.startsWith("client.");

  const { data: peopleData, isFetched: peopleDataFetched } = useQuery({
    queryKey: ["currentUser", user?.id],
    queryFn: () => getCurrentUser(supabaseClient, user?.id || "", isClient),
    enabled: !!user?.id,
    staleTime: Infinity
  });

  useEffect(() => {
    if (!session && initialSession) {
      setSession(initialSession);
      setUser(initialSession.user || null);
    }
  }, [session, initialSession]);

  useEffect(() => {
    let mounted = true;
    async function getSession() {
      setIsLoading(true);
      const {
        data: { session },
        error
      } = await supabaseClient.auth.getSession();

      if (mounted) {
        if (error) {
          setIsLoading(false);
          return;
        }

        setSession(session);
        setUser(session?.user || null);
        setIsLoading(false);
      }
      setIsLoading(false);
    }

    getSession();
    return () => {
      mounted = false;
    };
  }, []);

  // this method is currently only called when showing the quiz loader for a new user added
  // through a quiz, as we need to update the phone number of the user
  const updateNewUserPhone = useCallback(async () => {
    if (
      !user?.id ||
      user?.phone ||
      !user?.user_metadata?.phone ||
      user?.user_metadata?.source !== "Quiz" ||
      updatedClientRef?.current
    ) {
      return;
    }
    const { error } = await supabaseClient.auth.updateUser({
      phone: user?.user_metadata?.phone
    });
    updatedClientRef.current = true;
    if (error) {
      logError({
        error,
        source: "Client Quiz Loading - updateNewUserPhone",
        message: error?.message || "Error updating user phone",
        type: ERROR_TYPES.APP_ERROR,
        url: window.location.href,
        additionalInfo: {
          userPhone: user?.user_metadata?.phone,
          userId: user?.id,
          currentUser: peopleData?.data
        }
      });
      return;
    }
    const userData = await supabaseClient.auth.getUser();
    const updatedUser = userData?.data?.user;
    setUser(updatedUser);
  }, [
    supabaseClient.auth,
    user?.id,
    user?.phone,
    user?.user_metadata?.phone,
    user?.user_metadata?.source,
    peopleData?.data,
    logError
  ]);

  // This method is only called when a super admin changes the organization
  const updateCurrentUserOrgId = useCallback(
    (orgId?: string, redirectUrl?: string) => {
      const finalOrgId = orgId || peopleData?.data?.org_id;
      setCurrentUserOrgId(finalOrgId);
      // We need a full app reload to update the org id in the app
      window.location.href =
        "/table?orgid=" + finalOrgId + (redirectUrl ? `&${STATIC_SEARCH_PARAMS.redirectTo}=${redirectUrl}` : "");
    },
    [peopleData?.data?.org_id]
  );

  const isUserInValidOrg = useMemo(() => {
    if (!!peopleData?.data?.is_super_admin) {
      return true;
    }
    if (!peopleData?.data?.organization?.subdomain) {
      return false;
    }
    const currentSubDomain = typeof window !== "undefined" ? window.location.host.split(".")[0] : "";
    const isStaffUser = peopleData?.data?.type === USER_TYPE.STAFF;

    return (
      (isStaffUser &&
        (currentSubDomain === peopleData.data?.organization?.subdomain ||
          currentSubDomain.startsWith(peopleData.data?.organization?.subdomain))) ||
      (!isStaffUser && (currentSubDomain === "client" || currentSubDomain.startsWith("client")))
    );
  }, [peopleData?.data]);

  const finalPeopleData = useMemo(
    () =>
      !isUserInValidOrg
        ? undefined
        : peopleData?.data
          ? { ...peopleData.data, org_id: currentUserOrgId || peopleData.data.org_id }
          : peopleData?.data,
    [currentUserOrgId, peopleData?.data, isUserInValidOrg]
  );

  const handleLogoutUser = useCallback(async () => {
    const { error } = await supabaseClient.auth.signOut();

    if (error) {
      logError({
        error,
        source: "SupabaseSessionContextProvider - handleLogoutUser",
        message: error?.message || "Error logging out user",
        type: ERROR_TYPES.APP_ERROR,
        url: window.location.href,
        additionalInfo: {
          currentUser: peopleData?.data
        }
      });
    }
    router.refresh();
    router.push("/login");
  }, [supabaseClient.auth, router, peopleData?.data, logError]);

  useEffect(() => {
    if (!peopleData?.data?.id || !peopleData?.data?.organization?.subdomain) {
      return;
    }
    if (!isUserInValidOrg) {
      handleLogoutUser();
    }
  }, [peopleData?.data?.id, isUserInValidOrg, handleLogoutUser, peopleData?.data?.organization?.subdomain]);

  return (
    <Provider
      value={{
        session,
        user,
        isSessionLoading,
        currentUser: finalPeopleData,
        updateNewUserPhone,
        updateCurrentUserOrgId,
        isUserInValidOrg,
        peopleDataFetched
      }}
    >
      {children}
    </Provider>
  );
};

export default SupabaseSessionContextProvider;
