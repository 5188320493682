"use client";

import { GridColumnVisibilityModel, GridFilterItem, GridFilterModel } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import has from "lodash/has";
import React, { SetStateAction, createContext, useCallback, useRef, useState } from "react";

import {
  BeforeAfterFileViewState,
  InlineCreationState,
  NoteState,
  Page,
  PaginationState,
  SidebarState,
  SingleFileViewState,
  SortItem,
  TableColumnType,
  TableFilterType
} from "types/baTypes";
import { BulkActionState, CardSize, RecordItem, TableActionsContextSearchState } from "types/common";
import { GroupByInfo, RowDensity, STATIC_SIDEBAR_IDS, SidebarContainer } from "utils/constants";

export type PaginationUpdateParams = {
  currentPage?: number;
  pageSize?: number;
  totalCount?: number;
};

export interface MuiFilterItem extends GridFilterItem {
  header: string;
  column: TableColumnType;
  id: string;
}

export interface MuiFilterModel extends GridFilterModel {
  items: MuiFilterItem[];
}

export type CardApi = {
  selectCard(rowId: string | null): void;
};
export type GridApi = {
  selectRow(rowId: string | null): void;
  gridRef: GridApiPremium;
};
type BoardApi = {
  selectCard(rowId: string | null): void;
};

type MapApi = {
  selectCard(rowId: string | null): void;
};

type TableRowsStateBySlugRecord = {
  rowIds: string[];
  totalCount?: number;
  firstViewIdsSet?: string[]; // save the first 50 ids to use when creating a new record
  countByRecordId?: RecordItem; // keep track of the total count for each record
};
type TableRowsStateBySlug = { [key: string]: TableRowsStateBySlugRecord };

type TableSearchStateBySlug = {
  [key: string]: TableActionsContextSearchState;
};

export type MapState = {
  isOpen?: boolean;
  items?: {
    id: string;
    longitude: number;
    latitude: number;
  }[];
  address?: {
    address_line_1?: string;
    address_line_2?: string;
    city?: string;
    state?: string;
    zip_code?: string;
  };
};

type SendToAction = {
  sortColumnName?: string;
  firstSortRank?: string;
  lastSortRank?: string;
  isJoinTable?: boolean;
};

type RecordHistoryItem = {
  title: string;
  path: string;
};

export interface TableActionsContextState {
  tableRowsStateBySlug?: TableRowsStateBySlug;
  tableSearchStateBySlug?: TableSearchStateBySlug;
  cardApiRef: React.RefObject<CardApi>;
  gridApiRef: React.RefObject<GridApi>;
  boardApiRef: React.RefObject<BoardApi>;
  mapApiRef: React.RefObject<MapApi>;
  setTotalCount: (tableSlug: string, totalCount: number | undefined, recordId?: string) => void;
  setDataRowsIds: (tableSlug: string, rowIds: string[]) => void;
  setDataFirstRowsIds: (tableSlug: string, rowIds: string[]) => void;
  setTableSearchResults: (props: { tableSlug: string; searchState: TableActionsContextSearchState }) => void;
  sortingByTableSlug: { [tableSlug: string]: SortItem[] } | undefined;
  filtersByTableSlug: { [tableSlug: string]: TableFilterType[] } | undefined;
  sidebarState: SidebarState;
  inlineCreationState: InlineCreationState;
  singleFileViewState: SingleFileViewState;
  beforeAfterFileViewState: BeforeAfterFileViewState;
  muiFilterModel: MuiFilterModel | undefined;
  setCardApiRef: (ref: React.RefObject<CardApi>) => void;
  setGridApiRef: (ref: React.RefObject<GridApi>) => void;
  setBoardApiRef: (ref: React.RefObject<BoardApi>) => void;
  setMapApiRef: (ref: React.RefObject<MapApi>) => void;
  updateSortingByTableSlug: (sorting: SortItem[], tableSlug: string) => void;
  updateFiltersByTableSlug: (filters: TableFilterType[], tableSlug: string) => void;
  updateSidebarState: (updatedSidebarProps: SidebarState, sidebarId: STATIC_SIDEBAR_IDS) => void;
  updateInlineCreationState: (updatedSidebarProps: InlineCreationState) => void;
  updateSingleFileViewState: (updatedSidebarProps: SingleFileViewState) => void;
  updateBeforeAfterFileViewState: (updatedSidebarProps: BeforeAfterFileViewState) => void;
  setMuiFilterModel: (muiFilterModel: MuiFilterModel | undefined) => void;
  rowDensityByTableSlug?: { [tableSlug: string]: RowDensity };
  updateRowDensityByTableSlug: (rowDensity: RowDensity, tableSlug: string) => void;
  columnVisibilityModelByTableSlug?: {
    [tableSlug: string]: {
      [view: string]: GridColumnVisibilityModel;
    };
  };
  updateColumnVisibilityModelByTableSlug: (
    tableSlug: string,
    columnName: string,
    visible: boolean,
    view: string
  ) => void;
  updateColumnsVisibilityModelByTableSlug: (
    tableSlug: string,
    columnVisibilityModel: GridColumnVisibilityModel,
    view: string
  ) => void;
  currentTablePage: Page | undefined;
  updateCurrentTablePage: (page: Page) => void;
  paginationByTableSlug: { [tableSlug: string]: PaginationState } | undefined;
  updatePaginationByTableSlug: (
    updatedPaginationProps: PaginationUpdateParams,
    tableSlug: string,
    additionalProps?: { inAddMany: boolean }
  ) => void;
  bulkActionsStateByTableSlug: { [tableSlug: string]: BulkActionState } | undefined;
  updateBulkActionsStateByTableSlug: (updatedBulkActionsProps: Partial<BulkActionState>, tableSlug: string) => void;
  noteState: NoteState;
  updateNoteState: (updatedNoteProps: Partial<NoteState>) => void;
  currentProjectId: string | undefined;
  updateCurrentProjectId: (projectId: string | undefined) => void;
  currentRecordId: string | undefined;
  updateCurrentRecordId: (recordId: string | undefined) => void;
  currentRecordTableName?: string; // Tablename for currentRecordId (includes projects)
  updateCurrentRecordTableName: (tableName?: string) => void;
  mapState: MapState;
  updateMapState: (updatedMapProps: Partial<MapState>) => void;
  detailViewRecordTriggeredByPath: string | null | undefined;
  updateDetailViewRecordTriggeredByPath: (path: string | null | undefined) => void;
  detailTabViewFilterByTableSlug: { [tableSlug: string]: TableFilterType[] } | undefined;
  updateDetailTabViewFilterByTableSlug: (tableSlug: string, filters: TableFilterType[]) => void;
  groupByInfoByTableSlug:
    | {
        [tableSlug: string]: {
          [view: string]: GroupByInfo;
        };
      }
    | undefined;
  updateGroupByInfoByTableSlug: (groupByInfo: Partial<GroupByInfo>, tableSlug: string, view: string) => void;
  cardSizeByTableSlug: { [tableSlug: string]: string } | undefined;
  updateCardSizeByTableSlug: (tableSlug: string, cardSize: CardSize) => void;
  openFiltersByTableSlug: { [tableSlug: string]: boolean } | undefined;
  updateOpenFiltersByTableSlug: (open: boolean, tableSlug: string) => void;
  matrixXAxisColumnIdByTableSlug: { [tableSlug: string]: string } | undefined;
  updateMatrixXAxisColumnIdByTableSlug: (matrixXColumnId: string, tableSlug: string) => void;
  triggerAction?: { action?: string; recordId?: string }; // Used to indicate an action was clicked that triggers a function in a different component
  updateTriggerAction: (action?: string, recordId?: string) => void;
  sendToActionByTableSlug: { [tableSlug: string]: SendToAction } | undefined;
  updateSendToActionByTableSlug: (sendToAction: SendToAction, tableSlug: string) => void;
  getTotalCountByTableSlug: (tableSlug: string, totalCount?: number) => number | undefined;
  pageConfigErrors?: RecordItem[];
  updatePageConfigErrors: (pageConfigErrors?: RecordItem[]) => void;
  formWebhookAddedRecordId?: string; // If a new record is added via form webhook (db bypass), we update this with the record id when success notification is received
  updateFormWebhookAddedRecordId: (recordId?: string) => void;
  sectionFiltersByTableSlug?: { [tableSlug: string]: TableFilterType[] } | undefined;
  updateSectionFiltersByTableSlug: (filters: TableFilterType[], tableSlug: string) => void;
  gridPaginationByTableSlug?: { [tableSlug: string]: PaginationState } | undefined; // This is only used by Data Grid when pagination is enabled for it
  updateGridPaginationByTableSlug: (updatedPaginationProps: PaginationUpdateParams, tableSlug: string) => void; // This is only used by Data Grid when pagination is enabled for it
  recordViewParentPage?: Page; // This is set in Record view layout and is the parent page in the `/r` path
  updateRecordViewParentPage: (page?: Page) => void; // This is set in Record view layout and is the parent page in the `/r` path
  recordHistoryState: RecordHistoryItem[]; // This is used to keep track of the history of record nested route visited in the app
  deleteFromRecordHistoryState: (path: string) => void;
  updateRecordHistoryState: (newState: SetStateAction<RecordHistoryItem[]>) => void;
  superAdminOrgIdFilterDisabledByTableSlug?: { [tableSlug: string]: boolean } | undefined;
  updateSuperAdminOrgIdFilterDisabledByTableSlug: (disabled: boolean, tableSlug: string) => void;
}

export const TableActionsContext = createContext<TableActionsContextState | null>(null);

const { Provider } = TableActionsContext;

export const TableActionsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const cardApiRef = useRef(null);
  const gridApiRef = useRef(null);
  const boardApiRef = useRef(null);
  const mapApiRef = useRef(null);
  const [sidebarState, setSidebarState] = useState<SidebarState>({
    isOpen: false,
    container: SidebarContainer.Sidebar,
    visibleSidebars: []
  });
  const [tableRowsStateBySlug, setTableRowsStateBySlug] = useState<TableRowsStateBySlug>();
  const [tableSearchStateBySlug, setTableSearchStateBySlug] = useState<TableSearchStateBySlug>();
  const [inlineCreationState, setInlineCreationState] = useState<InlineCreationState>({ isOpen: false });
  const [singleFileViewState, setSingleFileViewState] = useState<SingleFileViewState>({ isOpen: false });
  const [beforeAfterFileViewState, setBeforeAfterFileViewState] = useState<BeforeAfterFileViewState>({ isOpen: false });
  const [muiFilterModel, setMuiFilterModel] = useState<MuiFilterModel | undefined>(undefined);
  const [rowDensityByTableSlug, setRowDensityByTableSlug] = useState<{ [tableSlug: string]: RowDensity }>();
  const [columnVisibilityModelByTableSlug, setColumnVisibilityModelByTableSlug] = useState<{
    [tableSlug: string]: {
      [view: string]: GridColumnVisibilityModel;
    };
  }>();
  const [currentTablePage, setCurrentTablePage] = useState<Page>();
  const [recordViewParentPage, setRecordViewParentPage] = useState<Page | undefined>();
  const [paginationByTableSlug, setPaginationByTableSlug] = useState<{ [tableSlug: string]: PaginationState }>();
  const [gridPaginationByTableSlug, setGridPaginationByTableSlug] = useState<{
    [tableSlug: string]: PaginationState;
  }>();
  const [sortingByTableSlug, setSortingByTableSlug] = useState<{ [tableSlug: string]: SortItem[] }>();
  const [filtersByTableSlug, setFiltersByTableSlug] = useState<{ [tableSlug: string]: TableFilterType[] }>();
  const [sectionFiltersByTableSlug, setSectionFiltersByTableSlug] = useState<{
    [tableSlug: string]: TableFilterType[];
  }>();
  const [openFiltersByTableSlug, setOpenFiltersByTableSlug] = useState<{ [tableSlug: string]: boolean }>();
  const [bulkActionsStateByTableSlug, setBulkActionsStateByTableSlug] = useState<{
    [tableSlug: string]: BulkActionState;
  }>();
  const [noteState, setNoteState] = useState<{ isCreating: boolean }>({ isCreating: false });
  const [currentProjectId, setCurrentProjectId] = useState<string | undefined>();
  const [currentRecordId, setCurrentRecordId] = useState<string | undefined>();
  const [currentRecordTableName, setCurrentRecordTableName] = useState<string | undefined>();
  const [mapState, setMapState] = useState<MapState>({ isOpen: false });
  const [detailViewRecordTriggeredByPath, setDetailViewRecordTriggeredByPath] = useState<string | null | undefined>();
  const [detailTabViewFilterByTableSlug, setDetailTabViewFilterByTableSlug] = useState<{
    [tableSlug: string]: TableFilterType[];
  }>();
  const [groupByInfoByTableSlug, setGroupByInfoByTableSlug] = useState<{
    [tableSlug: string]: {
      [view: string]: GroupByInfo;
    };
  }>();
  const [cardSizeByTableSlug, setCardSizeByTableSlug] = useState<{ [tableSlug: string]: string }>();
  const [matrixXAxisColumnIdByTableSlug, setMatrixXColumnIdByTableSlug] = useState<{ [tableSlug: string]: string }>();
  const [triggerAction, setTriggerAction] = useState<{ action?: string; recordId?: string } | undefined>();
  const [sendToActionByTableSlug, setSendToActionByTableSlug] = useState<{ [tableSlug: string]: SendToAction }>();
  const [pageConfigErrors, setPageConfigErrors] = useState<RecordItem[] | undefined>(undefined);
  const [formWebhookAddedRecordId, setFormWebhookAddedRecordId] = useState<string | undefined>(undefined);
  const [recordHistoryState, setRecordHistoryState] = useState<RecordHistoryItem[]>([]);
  const [superAdminOrgIdFilterDisabledByTableSlug, setSuperAdminOrgIdFilterDisabledByTableSlug] = useState<{
    [tableSlug: string]: boolean;
  }>();
  const updatePageConfigErrors = useCallback((pageConfigErrors?: RecordItem[]) => {
    if (!pageConfigErrors) {
      setPageConfigErrors(undefined);
    } else {
      setPageConfigErrors(pageConfigErrors);
    }
  }, []);

  const updateTriggerAction = useCallback((action?: string, recordId?: string) => {
    if (action && recordId) {
      setTriggerAction({
        action,
        recordId
      });
    } else {
      setTriggerAction(undefined);
    }
  }, []);

  const updateFormWebhookAddedRecordId = useCallback((recordId?: string) => {
    setFormWebhookAddedRecordId(recordId);
  }, []);

  const updateCurrentProjectId = useCallback((projectId: string | undefined) => {
    setCurrentProjectId(projectId);
  }, []);

  const updateCurrentRecordId = useCallback((recordId: string | undefined) => {
    setCurrentRecordId(recordId);
  }, []);

  const updateCurrentRecordTableName = useCallback((tableName?: string) => {
    setCurrentRecordTableName(tableName);
  }, []);

  const updateRecordViewParentPage = useCallback((page?: Page) => {
    setRecordViewParentPage(page);
  }, []);

  const updateDetailTabViewFilterByTableSlug = useCallback((tableSlug: string, filters: TableFilterType[]) => {
    setDetailTabViewFilterByTableSlug((prevState) => ({
      ...prevState,
      [tableSlug]: filters
    }));
  }, []);

  const setTotalCount = useCallback(
    (tableSlug: string, totalCount: number | undefined, recordId?: string) => {
      // When opening the same table for different records, we need to keep track of the total count for each record
      setTableRowsStateBySlug((prevState) => {
        const updatedCountByRecordId: { countByRecordId?: RecordItem } = {};
        if (recordId) {
          updatedCountByRecordId.countByRecordId = {
            ...prevState?.[tableSlug]?.countByRecordId,
            [recordId]: totalCount
          };
        }
        return prevState
          ? {
              ...prevState,
              [tableSlug]: {
                ...prevState?.[tableSlug],
                totalCount,
                ...updatedCountByRecordId
              }
            }
          : {
              [tableSlug]: {
                rowIds: [],
                totalCount,
                ...updatedCountByRecordId
              }
            };
      });
    },
    [setTableRowsStateBySlug]
  );

  const setDataRowsIds = useCallback(
    (tableSlug: string, rowIds: string[]) => {
      setTableRowsStateBySlug((prevState) => ({
        ...prevState,
        [tableSlug]: {
          ...prevState?.[tableSlug],
          rowIds
        }
      }));
    },
    [setTableRowsStateBySlug]
  );

  const setDataFirstRowsIds = useCallback(
    (tableSlug: string, rowIds: string[]) => {
      setTableRowsStateBySlug((prevState) =>
        prevState
          ? {
              ...prevState,
              [tableSlug]: {
                ...prevState?.[tableSlug],
                firstViewIdsSet: rowIds
              }
            }
          : {
              [tableSlug]: {
                rowIds: [],
                firstViewIdsSet: rowIds
              }
            }
      );
    },
    [setTableRowsStateBySlug]
  );

  const setTableSearchResults = useCallback(
    ({ tableSlug, searchState }: { tableSlug: string; searchState: TableActionsContextSearchState }) => {
      setTableSearchStateBySlug((prevState) => {
        let finalResultIds = searchState.resultIds?.length ? [...searchState.resultIds] : [];
        if ((searchState?.currentPage || 0) > 1) {
          // We append the resultIds to the existing resultIds if the currentPage is greater than 1
          finalResultIds = [...(prevState?.[tableSlug]?.resultIds || []), ...searchState.resultIds];
        }
        return {
          ...prevState,
          [tableSlug]: {
            ...prevState?.[tableSlug],
            ...searchState,
            resultIds: finalResultIds
          }
        };
      });
    },
    [setTableSearchStateBySlug]
  );

  const setCardApiRef = useCallback((newApiRef: { current: any }) => {
    cardApiRef.current = newApiRef?.current;
  }, []);

  const setGridApiRef = useCallback((newApiRef: { current: any }) => {
    gridApiRef.current = newApiRef?.current;
  }, []);

  const setBoardApiRef = useCallback((newApiRef: { current: any }) => {
    boardApiRef.current = newApiRef?.current;
  }, []);

  const setMapApiRef = useCallback((newApiRef: { current: any }) => {
    mapApiRef.current = newApiRef?.current;
  }, []);

  const updateDetailViewRecordTriggeredByPath = useCallback((path?: string | null) => {
    setDetailViewRecordTriggeredByPath(path);
  }, []);

  const updateSortingByTableSlug = useCallback((newSorting: SortItem[], tableSlug: string) => {
    setSortingByTableSlug((prev: { [tableSlug: string]: SortItem[] } | undefined) => ({
      ...(prev || {}),
      [tableSlug]: newSorting
    }));
  }, []);

  const updateFiltersByTableSlug = useCallback((newFilters: TableFilterType[], tableSlug: string) => {
    setFiltersByTableSlug((prev: { [tableSlug: string]: TableFilterType[] } | undefined) => ({
      ...(prev || {}),
      [tableSlug]: newFilters
    }));
  }, []);

  const updateSectionFiltersByTableSlug = useCallback((newFilters: TableFilterType[], tableSlug: string) => {
    setSectionFiltersByTableSlug((prev: { [tableSlug: string]: TableFilterType[] } | undefined) => ({
      ...(prev || {}),
      [tableSlug]: newFilters
    }));
  }, []);

  const updateOpenFiltersByTableSlug = useCallback((newOpenFilters: boolean, tableSlug: string) => {
    setOpenFiltersByTableSlug((prev: { [tableSlug: string]: boolean } | undefined) => ({
      ...(prev || {}),
      [tableSlug]: newOpenFilters
    }));
  }, []);

  const updateCurrentTablePage = useCallback((newPage: Page) => {
    setCurrentTablePage(newPage);
  }, []);

  const updateSidebarState = useCallback((updatedSidebarProps: SidebarState, sidebarId: STATIC_SIDEBAR_IDS) => {
    setSidebarState((prev) => {
      const visibleSidebars = prev.visibleSidebars ? [...prev.visibleSidebars] : [];
      switch (sidebarId) {
        case STATIC_SIDEBAR_IDS.PAGE_VIEW_CONFIG_EDIT_SIDEBAR:
          if (updatedSidebarProps.pageViewConfigEditState?.isOpen) {
            visibleSidebars.push(sidebarId);
          } else if (visibleSidebars[visibleSidebars.length - 1] === sidebarId) {
            visibleSidebars.pop();
          }
          break;
        case STATIC_SIDEBAR_IDS.MAIN_SIDEBAR:
        case STATIC_SIDEBAR_IDS.COLUMN_CONFIG_EDIT_SIDEBAR:
          if (updatedSidebarProps.isOpen) {
            visibleSidebars.push(sidebarId);
          } else if (visibleSidebars[visibleSidebars.length - 1] === sidebarId) {
            visibleSidebars.pop();
          }
          break;
        case STATIC_SIDEBAR_IDS.AUDIT_SIDEBAR:
          if (updatedSidebarProps.showAuditSidebar) {
            visibleSidebars.push(sidebarId);
          } else if (visibleSidebars[visibleSidebars.length - 1] === sidebarId) {
            visibleSidebars.pop();
          }
          break;
        case STATIC_SIDEBAR_IDS.ADD_SIDEBAR:
          if (updatedSidebarProps.addState?.isOpen) {
            visibleSidebars.push(sidebarId);
          } else if (visibleSidebars[visibleSidebars.length - 1] === sidebarId) {
            visibleSidebars.pop();
          }
          break;
        case STATIC_SIDEBAR_IDS.MENU_CONFIG_EDIT_SIDEBAR:
          if (updatedSidebarProps.menuConfigEditState?.isOpen) {
            visibleSidebars.push(sidebarId);
          } else if (visibleSidebars[visibleSidebars.length - 1] === sidebarId) {
            visibleSidebars.pop();
          }
          break;
        case STATIC_SIDEBAR_IDS.SEARCH_TABLE_CONFIG_EDIT_SIDEBAR:
          if (updatedSidebarProps.searchTableConfigEditState?.isOpen) {
            visibleSidebars.push(sidebarId);
          } else if (visibleSidebars[visibleSidebars.length - 1] === sidebarId) {
            visibleSidebars.pop();
          }
        default:
          break;
      }

      // if updatedSidebarState has isOpen key and value is false, remove MAIN_SIDEBAR and COLUMN_CONFIG_EDIT_SIDEBAR from visibleSidebars
      if (has(updatedSidebarProps, "isOpen") && !updatedSidebarProps.isOpen) {
        visibleSidebars.splice(visibleSidebars.indexOf(STATIC_SIDEBAR_IDS.MAIN_SIDEBAR), 1);
        visibleSidebars.splice(visibleSidebars.indexOf(STATIC_SIDEBAR_IDS.COLUMN_CONFIG_EDIT_SIDEBAR), 1);
      }
      return { ...prev, ...updatedSidebarProps, visibleSidebars };
    });
  }, []);

  const updateInlineCreationState = useCallback((updatedInlineCreationProps: InlineCreationState) => {
    setInlineCreationState((prev) => ({ ...prev, ...updatedInlineCreationProps }));
  }, []);

  const updateSingleFileViewState = useCallback((updatedSingleFileViewProps: SingleFileViewState) => {
    setSingleFileViewState((prev) => ({ ...prev, ...updatedSingleFileViewProps }));
  }, []);

  const updateBeforeAfterFileViewState = useCallback((updatedBeforeAfterFileViewProps: BeforeAfterFileViewState) => {
    setBeforeAfterFileViewState((prev) => ({ ...prev, ...updatedBeforeAfterFileViewProps }));
  }, []);

  const updatePaginationByTableSlug = useCallback(
    (updatedPaginationProps: PaginationUpdateParams, tableSlug: string, additionalProps?: { inAddMany: boolean }) => {
      const finalSlug = additionalProps?.inAddMany ? `${tableSlug}_add_many` : tableSlug;
      setPaginationByTableSlug((prev: any) => ({
        ...(prev || {}),
        [finalSlug]: { ...updatedPaginationProps }
      }));
    },
    []
  );

  const updateGridPaginationByTableSlug = useCallback(
    (updatedPaginationProps: PaginationUpdateParams, tableSlug: string, additionalProps?: { inAddMany: boolean }) => {
      const finalSlug = additionalProps?.inAddMany ? `${tableSlug}_add_many` : tableSlug;
      setGridPaginationByTableSlug((prev: any) => ({
        ...(prev || {}),
        [finalSlug]: { ...updatedPaginationProps }
      }));
    },
    []
  );

  const updateRowDensityByTableSlug = useCallback((rowDensity: RowDensity, tableSlug: string) => {
    setRowDensityByTableSlug((prev: { [tableSlug: string]: RowDensity } | undefined) => ({
      ...(prev || {}),
      [tableSlug]: rowDensity
    }));
  }, []);

  const updateGroupByInfoByTableSlug = useCallback(
    (groupByInfo: Partial<GroupByInfo>, tableSlug: string, view: string) => {
      setGroupByInfoByTableSlug(
        (
          prev:
            | {
                [tableSlug: string]: {
                  [view: string]: GroupByInfo;
                };
              }
            | undefined
        ) => ({
          ...(prev || {}),
          [tableSlug]: {
            ...prev?.[tableSlug],
            [view]: {
              ...(prev?.[tableSlug]?.[view] || {}),
              ...groupByInfo
            }
          }
        })
      );
    },
    []
  );

  const updateColumnVisibilityModelByTableSlug = useCallback(
    (tableSlug: string, columnName: string, visible: boolean, view: string) => {
      setColumnVisibilityModelByTableSlug((prev) => {
        return {
          ...(prev || {}),
          [tableSlug]: {
            ...prev?.[tableSlug],
            [view]: {
              ...(prev?.[tableSlug]?.[view] || {}),
              [columnName]: visible
            }
          }
        };
      });
    },
    []
  );

  const updateColumnsVisibilityModelByTableSlug = useCallback(
    (tableSlug: string, columnsToChange: GridColumnVisibilityModel, view: string) => {
      setColumnVisibilityModelByTableSlug((prev) => {
        return {
          ...(prev || {}),
          [tableSlug]: {
            ...prev?.[tableSlug],
            [view]: {
              ...(prev?.[tableSlug]?.[view] || {}),
              ...columnsToChange
            }
          }
        };
      });
    },
    []
  );

  const updateBulkActionsStateByTableSlug = useCallback(
    (updatedBulkActionsProps: Partial<BulkActionState>, tableSlug: string) => {
      setBulkActionsStateByTableSlug((prev: { [tableSlug: string]: BulkActionState } | undefined) => ({
        ...(prev || {}),
        [tableSlug]: { ...prev?.[tableSlug], ...updatedBulkActionsProps }
      }));
    },
    []
  );

  const updateNoteState = useCallback((updatedNoteProps: Partial<{ isCreating: boolean }>) => {
    setNoteState((prev) => ({ ...prev, ...updatedNoteProps }));
  }, []);

  const updateMapState = useCallback((updatedMapProps: Partial<MapState>) => {
    setMapState((prev) => ({ ...prev, ...updatedMapProps }));
  }, []);

  const updateCardSizeByTableSlug = useCallback((tableSlug: string, cardSize: CardSize) => {
    setCardSizeByTableSlug((prev: { [tableSlug: string]: string } | undefined) => ({
      ...(prev || {}),
      [tableSlug]: cardSize
    }));
  }, []);

  const updateMatrixXAxisColumnIdByTableSlug = useCallback((matrixXColumnIdUpdated: string, tableSlug: string) => {
    setMatrixXColumnIdByTableSlug((prev: { [tableSlug: string]: string } | undefined) => ({
      ...(prev || {}),
      [tableSlug]: matrixXColumnIdUpdated
    }));
  }, []);

  const updateSendToActionByTableSlug = useCallback((updatedSendToAction: SendToAction, tableSlug: string) => {
    setSendToActionByTableSlug((prev: { [tableSlug: string]: SendToAction } | undefined) => ({
      ...(prev || {}),
      [tableSlug]: updatedSendToAction
    }));
  }, []);

  const getTotalCountByTableSlug = useCallback(
    (tableSlug: string, totalCount?: number) => {
      const isAlgoliaEmptySearchResults =
        tableSearchStateBySlug?.[tableSlug] &&
        tableSearchStateBySlug?.[tableSlug]?.searchValue?.length > 3 &&
        !tableSearchStateBySlug?.[tableSlug]?.resultIds.length;
      const algoliaSearchResultsCount =
        (tableSearchStateBySlug?.[tableSlug]?.searchValue?.length || 0) > 3
          ? tableSearchStateBySlug?.[tableSlug]?.totalCount
          : undefined;
      if (algoliaSearchResultsCount) {
        return algoliaSearchResultsCount;
      }
      const finalTotalCount =
        totalCount !== undefined
          ? totalCount
          : isAlgoliaEmptySearchResults
            ? 0
            : tableRowsStateBySlug?.[tableSlug]?.totalCount;
      return finalTotalCount;
    },
    [tableRowsStateBySlug, tableSearchStateBySlug]
  );

  const updateSuperAdminOrgIdFilterDisabledByTableSlug = useCallback((disabled: boolean, tableSlug: string) => {
    setSuperAdminOrgIdFilterDisabledByTableSlug((prev: { [tableSlug: string]: boolean } | undefined) => ({
      ...(prev || {}),
      [tableSlug]: disabled
    }));
  }, []);

  const updateRecordHistoryState = useCallback((newState: SetStateAction<RecordHistoryItem[]>) => {
    setRecordHistoryState(newState);
  }, []);

  const deleteFromRecordHistoryState = useCallback((path: string) => {
    setRecordHistoryState((prev) => prev.filter((item) => item.path !== path));
  }, []);
  return (
    <Provider
      value={{
        cardApiRef,
        gridApiRef,
        boardApiRef,
        mapApiRef,
        setCardApiRef,
        setGridApiRef,
        setBoardApiRef,
        setMapApiRef,
        setTotalCount,
        setDataRowsIds,
        setDataFirstRowsIds,
        setTableSearchResults,
        tableRowsStateBySlug,
        tableSearchStateBySlug,
        sortingByTableSlug,
        updateSortingByTableSlug,
        filtersByTableSlug,
        updateFiltersByTableSlug,
        sidebarState,
        updateSidebarState,
        muiFilterModel,
        setMuiFilterModel,
        inlineCreationState,
        updateInlineCreationState,
        singleFileViewState,
        updateSingleFileViewState,
        beforeAfterFileViewState,
        updateBeforeAfterFileViewState,
        rowDensityByTableSlug,
        updateRowDensityByTableSlug,
        columnVisibilityModelByTableSlug,
        updateColumnVisibilityModelByTableSlug,
        updateColumnsVisibilityModelByTableSlug,
        currentTablePage,
        updateCurrentTablePage,
        paginationByTableSlug,
        updatePaginationByTableSlug,
        bulkActionsStateByTableSlug,
        updateBulkActionsStateByTableSlug,
        noteState,
        updateNoteState,
        currentProjectId,
        updateCurrentProjectId,
        currentRecordId,
        updateCurrentRecordId,
        currentRecordTableName,
        updateCurrentRecordTableName,
        mapState,
        updateMapState,
        detailViewRecordTriggeredByPath,
        updateDetailViewRecordTriggeredByPath,
        detailTabViewFilterByTableSlug,
        updateDetailTabViewFilterByTableSlug,
        groupByInfoByTableSlug,
        updateGroupByInfoByTableSlug,
        cardSizeByTableSlug,
        updateCardSizeByTableSlug,
        openFiltersByTableSlug,
        updateOpenFiltersByTableSlug,
        matrixXAxisColumnIdByTableSlug,
        updateMatrixXAxisColumnIdByTableSlug,
        triggerAction,
        updateTriggerAction,
        sendToActionByTableSlug,
        updateSendToActionByTableSlug,
        getTotalCountByTableSlug,
        pageConfigErrors,
        updatePageConfigErrors,
        formWebhookAddedRecordId,
        updateFormWebhookAddedRecordId,
        sectionFiltersByTableSlug,
        updateSectionFiltersByTableSlug,
        gridPaginationByTableSlug,
        updateGridPaginationByTableSlug,
        recordViewParentPage,
        updateRecordViewParentPage,
        recordHistoryState,
        deleteFromRecordHistoryState,
        updateRecordHistoryState,
        superAdminOrgIdFilterDisabledByTableSlug,
        updateSuperAdminOrgIdFilterDisabledByTableSlug
      }}
    >
      {children}
    </Provider>
  );
};
