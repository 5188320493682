import isEmpty from "lodash/isEmpty";
import { TableFilterType, TableColumnType, SortItem } from "types/baTypes";
import { RecordItem } from "types/common";
import { FILTER_OPERATOR, FILTER_OPERATOR_SEARCH_PARAMS, CellType } from "./constants";

export const getColumnSearchParamName = (col: TableFilterType | TableColumnType) =>
  "f_" + (col.header?.toLowerCase().replace(" ", "_") || "");

export const getFilterValFromSearchParams = (columns: TableColumnType[], params: RecordItem) => {
  const updatedFilters: TableFilterType[] = [];
  Object.keys(params).forEach((searchParam) => {
    //ignore sort item keys and search key
    if (searchParam.startsWith("sort") || searchParam === "search") return;

    const [filterNameVal, filterOperatorParam] = searchParam.split(":");
    const filterName = filterNameVal.replace("!", "");
    const filterCol = columns.find((col) => filterName === getColumnSearchParamName(col));
    if (filterCol) {
      let filterValue = params[searchParam];
      let filterOperator = filterNameVal.endsWith("!")
        ? filterValue === "null"
          ? FILTER_OPERATOR.NOT_EMPTY
          : FILTER_OPERATOR.NOT_EQUALS
        : filterValue === "null"
        ? FILTER_OPERATOR.EMPTY
        : FILTER_OPERATOR.EQUALS;

      switch (filterOperatorParam) {
        case FILTER_OPERATOR_SEARCH_PARAMS.GREATER_THAN:
          filterOperator = FILTER_OPERATOR.GREATER_THAN;
          break;
        case FILTER_OPERATOR_SEARCH_PARAMS.LESS_THAN:
          filterOperator = FILTER_OPERATOR.LESS_THAN;
          break;
        case FILTER_OPERATOR_SEARCH_PARAMS.IN:
          filterOperator = FILTER_OPERATOR.IN;
          break;
      }

      if (Array.isArray(filterValue)) {
        filterOperator = FILTER_OPERATOR.IN;
        filterValue = filterValue.map((val) =>
          filterCol.isLookup
            ? {
                record: {
                  id: val,
                },
              }
            : val
        );
      } else {
        filterValue =
          filterValue === "null"
            ? null
            : filterCol.isLookup
            ? {
                record: {
                  id: filterValue,
                },
              }
            : filterValue;
        if (filterOperator === FILTER_OPERATOR.IN) {
          filterValue = [filterValue];
        }
      }
      const isFilterTextSearch =
        filterCol &&
        !!filterCol?.name &&
        filterCol?.dbType?.format === "text" &&
        !(filterCol.type === CellType.SELECT && !isEmpty(filterCol.columnOptionsLookUp)) &&
        (filterOperator === FILTER_OPERATOR.EQUALS || !filterOperator);
      updatedFilters.push({
        ...filterCol,
        column: filterCol,
        filterOperator,
        filterValue,
        filterField: filterName,
        isFilterTextSearch,
      });
    }
  });

  return updatedFilters;
};

export const getSearchParamsFromFilters = (filters: TableFilterType[]) => {};

export const getSortItemsFromSearchParams = (columns: TableColumnType[], params: RecordItem) => {
  const updatedSortItems: SortItem[] = [];
  const sortParamKeys = Object.keys(params).filter((param) => param.startsWith("sort"));

  sortParamKeys.forEach((paramKey) => {
    const itemId = paramKey.split(":")[1];
    const col = columns.find((col) => itemId === getColumnSearchParamName(col));
    if (!itemId || !col) return;

    const sortItem: SortItem = {
      id: itemId,
      desc: params[paramKey] === "desc",
      col,
      name: col.header,
    };
    updatedSortItems.push(sortItem);
  });
  return updatedSortItems;
};

export const getSearchQueryFromParams = (params: RecordItem) => {
  return params["search"] ?? "";
};
